import React, {
  Component,
  useRef,
  useEffect,
  useContext,
  useState,
} from "react";
import Location from "../../../includes/location/Location";
import case1 from "../../../assets/Form/briefcase.svg";
import { Link, useParams } from "react-router-dom";
import attach from "../../../assets/fields/Attach.png";
import plus from "../../../assets/fields/Plus Math.png";
import document from "../../../assets/fields/document-text.svg";
import material from "../../../assets/Form/material-symbols_zoom-out-map-rounded.svg";
import download from "../../../assets/fields/import.svg";
import { serverApi } from "../../../../App";
import { Axios } from "../../../api/Axios";
import word from "../../../assets/Form/svgexport-18 1.svg";
import pdf from "../../../assets/Form/svgexport-10 (18) 1.svg";
import rar from "../../../assets/Form/svgexport-6 (2) 1.svg";
import View_attachments from "../../../components/view_attachments/View_attachments";
import LoadContext from "../../../components/loader/LoaderContext";
import { error } from "../../../notifications/Toast";

const View_task = () => {
  let submitBtn = useRef(0);
  const { id } = useParams();
  const{setLoader}= useContext(LoadContext)
  const [task, setTask] = useState([]);
  console.log("🚀 ~ file: View_task.jsx:25 ~ task:", task);

  let string = [];

  task?.technologies?.map((tech) => {
    string.push(tech.name + " , ");
  });

  const fetchPost = async () => {
    try {
      await Axios({
        method: "Get",
        url: `/Phases/tasks/${id}`,
      }).then((res) => {
        setTask(res?.data?.data?.task);
      });
    } catch (err) {
      error(err.response.data.message)

    }
    finally{
      setLoader(false)

    }
  };

  useEffect(() => {
    fetchPost();
    setLoader(true)
  }, []);

 
  const items = [
    { name: "Project Name", data: task.title },
    { name: "Project Phase", data: task.phase_name },
    { name: "Task Title", data: task.title },
    { name: " Start Date ", data: task.start },
    { name: "Deadline ", data: task.end },
    { name: "Status", data: task.status },
    { name: "Priority", data: task.priority },
    { name: "Team Member", data: [...string] },
  ];

  return (
    <>
      <Location main="Task View" head={task.title} />
      <div className="dash__form">
        <div className="dash__form-header">
          <img src={case1} alt="case" />
          <p style={{ color: "#fff" }}>Task Information</p>
        </div>
        <form>
          <div className="dash__viewtask">
            <div className="dash__viewtask-information">
              <h2 className="head">General Information :</h2>
              <div className="dash__viewtask-information_content grid grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 gap-4 ">
                {items.map((item) => {
                  return (
                    <div className="dash__viewtask-information_content-item">
                      <h3>{item.name}&nbsp;:</h3>
                      <p>&nbsp;{item.data}</p>
                    </div>
                  );
                })}
              </div>
            </div>

            <View_attachments links={task?.links} attachs={task?.attachments} />

            <div className="dash__viewtask-details">
              <h2 className="head">task Description :</h2>
              <div className="dash__viewtask-details_content">
                <h2>{task.description}</h2>
              </div>
            </div>
          </div>

          <button
            type="sumbit"
            hidden
            ref={submitBtn}
            onClick={(e) => e.preventDefault()}
          ></button>
        </form>

        <div className="dash__form-confirm">
          <Link hidden type="submit" /*onClick={()=>{handleSubmit()}}*/>
            {" "}
            Create{" "}
          </Link>
          <Link to="/Tasks List"> Back </Link>
        </div>
      </div>
    </>
  );
};

export default View_task;
