import axios from 'axios';
import Cookies from 'js-cookie';
import { error, notify } from '../notifications/Toast';





export const Axios = axios.create({
  baseURL: 'http://216.219.83.182/Alexon_Management/public/api',
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${localStorage.getItem('token') && JSON.parse(localStorage.getItem('token')).user.token }`
  }
});



  // ${Cookies.get('token') &&JSON.parse(Cookies.get('token')).user.token }


  // Axios.interceptors.response.use(
  //   (response) => response,
  //   (err) => {
  //     if (err.response) {
  //       // The request was made and the server responded with an error status code
  //       error( err.response.data.message);
  //     } else if (err.request) {
  //       // The request was made but no response was received
  //       error( err.response.data.message);
  //     } else {
  //       // Something happened in setting up the request that triggered an error
  //       error( err.response.data.message);
  //     }
  //   }
  // );

 export async function makeRequest(url, method, data) {
  try {
    const response = await Axios({
      method,
      url,
      data,
    });

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// Example usage:
// const todo = await makeRequest('https://jsonplaceholder.typicode.com/todos/1', 'get');
// console.log(todo);