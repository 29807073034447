import React, { useEffect, useState ,useRef, useContext } from 'react'
import './FieldCard.css'

import dots from '../../assets/fields/more.svg'
import user from '../../assets/fields/solar_user-rounded-linear.svg'
import scroll from '../../assets/fields/scroll.svg'
import { Link } from 'react-router-dom'

import { Axios } from '../../api/Axios'
import LoadContext from '../loader/LoaderContext'
import { error } from '../../notifications/Toast'



const FieldCard = ({api,link,fun,num}) => {
  const [fields ,setFields] = useState([])

 const {setLoader} = useContext(LoadContext)



  const fetch = async()=>{
    await Axios({
       method: "Get",
        url: `${api}`,
     }).then((res)=>{ setFields(res.data.data.Departments || res.data.data.Subdepartments);setLoader(false);  
     })
     .catch((err)=>{
      setLoader(false)
      error(err.response.data.message)
     })
    
    }


  useEffect(()=>{
    setLoader(true)
   fetch();
   
  },[])

  num(fields.length)


  return (
    <>
    {fields.map((field)=>{

      
      return(
        <div className='field_card  ' onClick={()=>{fun(field.id)}}>
            <div>
              <h6>{field.title || field.name}</h6>
              <img src={dots} alt='dots'/>
            </div>
            <div>
              <img src={user} alt='user'/>
              <p> {field.employee_count ||field.employees_count} member</p>
            </div>
          <div>
              <img src={scroll} alt='scroll'/>
              <p>{field.technology_count} technologies</p>
            </div>
          
       </div>
      )
    })

    }
    </>
  )
}

export default FieldCard