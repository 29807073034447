import React, { useState } from "react";
import download from "../../assets/fields/import.svg";
import attach from "../../assets/fields/Attach.png";
import pdf from "../../assets/Form/svgexport-10 (18) 1.svg";
import trash from "../../assets/Form/trash.svg";
import material from "../../assets/Form/material-symbols_zoom-out-map-rounded.svg";
import "./View_attachments.css";
import { IoCloseSharp } from "react-icons/io5";

const View_attachments = ({ links, attachs }) => {
  let [scaleimg, setScaleimg] = useState([]);

  return (
    <div>
      <div className="dash__viewtask-attachments">
        <h2 className="head">URL :</h2>

        <div className="dash__viewtask-attachments_content">
          {links && (
            <div>
              <div className="attach-head">
                <img src={attach} alt="" />
                <h3>Document Links</h3>
              </div>
              <div className="dash__form-content_links">
                {links?.map((link) => {
                  return (
                    <div className="dash__form-content_links-view">
                      <h3>{link}</h3>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {attachs && (
            <>
              <div>
                <div className="attach-head">
                  <img src={attach} alt="" />
                  <h3>Attachments</h3>
                </div>

                <div
                  className="dash__form-content_attach_upload flex"
                  style={{
                    gap: "25px",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                  }}
                >
                  {attachs?.map((att, index) => {
             
                      return (
                        <div
                          className="dash__form-content_attach_upload-image" /* key={project.id}*/
                        >
                          <div className="dash__form-content_attach_upload-image_file">
                            <img
                              style={
                                scaleimg[index]
                                  ? { scale: "2", zIndex: "10" }
                                  : {}
                              }
                              src={att.attachment_path}
                              alt="attach img"
                            />
                            {scaleimg[index] && (
                              <div
                                className="flex justify-center items-center bg-black rounded-full"
                                style={{
                                  position: "absolute",
                                  top: "-70px",
                                  right: "-70px",
                                  zIndex: "111",
                                  width: "30px",
                                  height: "30px",
                                }}
                              >
                                <IoCloseSharp
                                  color="#fff"
                                  size={27}
                                  onClick={() => {
                                    setScaleimg([]);
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          <div className="dash__form-content_attach_upload-image_hover">
                            <img
                              src={material}
                              alt="material"
                              onClick={() => {
                                setScaleimg(() => {
                                  let arr = [];
                                  arr[index] = 1;
                                  return arr;
                                });
                              }}
                            />
                          </div>

                          {/* <div className='dash__form-content_attach_upload-image_zoom'> 

                                                   <img src={attachment.attachment_path} alt='attach img'/>

                                                </div> */}
                        </div>
                      );
                    
                  })}
                </div>
              </div>

              <div>
                <div className="attach-head">
                  <img src={document} alt="" />
                  <h3> Documents</h3>
                </div>

                <div className="attach-files">
                  {attachs?.map((attachment) => {
                    if (attachment.attachment_type !== "image") {
                      return (
                        <div className="attach-file">
                          <div>
                            <img src={pdf} alt="files" />
                          </div>
                          <div>
                            <h3
                              style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {attachment.file_name}
                            </h3>
                            <p> {attachment.size}</p>
                          </div>
                          <div style={{ marginLeft: "auto" }}>
                            <img src={download} alt="" />
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default View_attachments;
